import React from "react"
import { Switch, Route } from "@chainsafe/common-components"
import HomePage from "./Pages/HomePage"

export const ROUTE_LINKS = {
  Dashboard: "/",
  Leaderboard: "/leaderboard",
  "Data & Metrics": "/data-metrics",
  FAQ: "/faq",
}

export const CURRENCY_TOGGLE_VISIBLE = [
  ROUTE_LINKS.Dashboard,
  ROUTE_LINKS.Leaderboard
]

const FilesRoutes = () => {
  return (
    <Switch>
      <Route
        exact
        path={ROUTE_LINKS.Dashboard}
        component={HomePage}
      />
        
    </Switch>
  )
}

export default FilesRoutes
